// Custom Theming for Angular Material
@use "@angular/material" as mat;
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$tdb-ui-primary: mat.define-palette(mat.$indigo-palette);
$tdb-ui-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$tdb-ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$tdb-ui-theme: mat.define-light-theme(
  $tdb-ui-primary,
  $tdb-ui-accent,
  $tdb-ui-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($tdb-ui-theme);

@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-balham.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";

// @import "~@ag-grid-community/all-modules/dist/styles/ag-grid.css";
// @import "~@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
// @import "~@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css";

// @import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import "~font-awesome/css/font-awesome.css";

//@import "../node_modules/ag-grid-community/src/styles/ag-grid.scss";
//@import "../node_modules/ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";

// .ag-theme-alpine {
//      @include ag-theme-alpine();
// }
@import url(../src/assets/styles/robot.css);
@import url(../src/assets/styles/material.icon);

html,
body {
  height: 100%;
}
// .ag-theme-alpine {
//   --ag-foreground-color: rgb(126, 46, 132);
// }
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
mat-dialog-container#modal-component {
  // border: 3px solid #262626;
  //border-radius: 11px;
  background-color: white;
  margin-right: 11px;
  margin-left: 6px;
  padding-left: 13px !important;
}

.alert-warning {
  margin-left: 1%;
  margin-right: 1%;
}

.alert-success {
  margin-left: 1%;
  margin-right: 1%;
}
.alert-danger {
  margin-left: 1%;
  margin-right: 1%;
}
.alert-dismissible {
  padding-right: 0rem !important;
}
.freezedivider {
  border-right: 3px solid #bcd7ec;
  width: 18%;
}
.fileName {
  text-decoration: none;
  color: #d20000;
  font: normal normal Bold 16px/14px Roboto, sans-serif !important;
}
.myPanelClass {
  margin-top: 25px !important;
  height: 80% !important;
}
.mat-select-panel {
  min-width: 100% !important;
}

.ag-theme-balham .ag-root-wrapper {
  border: solid 1px;
  border-color: #bdc3c7;
  border-color: var(--ag-border-color, #bdc3c7);
  // right:1% !important;
}
.own {
  border: solid 1px;
  border-color: #bdc3c7;
  border-color: var(--ag-border-color, #bdc3c7);
  right: 1% !important;
}
.mat-dialog-container {
  display: block;
  //padding: 24px;
  border-radius: 10px !important;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100% !important ;
  height: 100% !important ;
  min-height: inherit;
  max-height: inherit;
  padding-top: 15px !important;
}
.form-group .form-control {
  background: #fff;
  height: 30px;
  -webkit-box-shadow: inset 2px 2px 6px 0px rgba(1, 1, 1, 0.1);
  box-shadow: inset 2px 2px 6px 0px rgba(1, 1, 1, 0.1) !important;
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.form-group textarea {
  background: #fff;
}
.form-group .dropdown {
  background: #fff;
}

.ag-header-cell-text {
  font: normal normal bold 13px/16px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #161718;
  opacity: 1;
  white-space: pre-line;
  text-align: center;
}
.ag-header-row-floating-filter {
  background: #f4f0df 0% 0% no-repeat padding-box !important;
}
.btn-outline-danger {
  padding: 0px 28px !important;
  font-size: 12px !important;
  border-radius: 35px !important;
  font-weight: 600 !important;
}
.btn-outline-success {
  padding: 0px 17px !important;
  font-size: 12px !important;
  border-radius: 35px !important;
  font-weight: 600 !important;
  // background-color: #198754 !important;
  // color:#ffffff !important;
}

.btn-outline-warning {
  padding: 0px 12px !important;
  font-size: 12px !important;
  border-radius: 35px !important;
  font-weight: 600 !important;
}
// .btn:hover {
//   color: none !important;
//   background-color: none !important;
//   border-color: none !important;
// }
// .btn-outline-success
//   :focus,.btn:active {
//   outline: none !important;
//   background-color: none !important;
//   box-shadow: none;
// -webkit-box-shadow: none
// }
.btn-outline-success:hover {
  background-color: none !important;
}

//  .mat-h2, .mat-title, .mat-typography h2 {
//   font: 480 13px/32px Roboto, "Helvetica Neue", sans-serif !important;
//   letter-spacing: normal;
//   margin: 0 0 16px;
// }

.grid-cell-edit-layout {
  width: 70px;
  height: 26px;
  border: 1px solid #d1d1d1;
}

.margin-imagetag-play {
  margin-left: 1rem;
  padding: 4px;
  border: 1px solid #d1d1d1;
  background: transparent linear-gradient(180deg, #ffffff 0%, #e4ecf0 100%) 0%
    0% no-repeat padding-box;
  //margin-left: 15px;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.margin-imagetag-download {
  //margin-left: 1rem;
  padding: 4px;
  border: 1px solid #d1d1d1;
  background: transparent linear-gradient(180deg, #ffffff 0%, #e4ecf0 100%) 0%
    0% no-repeat padding-box;
  //margin-left: 15px;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.margin-imagetag-download1 {
  margin-left: 3rem;
  padding: 4px;
  border: 1px solid #d1d1d1;
  background: transparent linear-gradient(180deg, #ffffff 0%, #e4ecf0 100%) 0%
    0% no-repeat padding-box;
  //margin-left: 15px;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.ag-input-field-input {
  height: 22px;
  //width: 80px;
}
.ag-theme-balham .ag-icon {
  // font-family: "agGridBalham";
  font-size: 20px !important;
  line-height: 17px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  color: #000000;
  span.ag-icon.ag-icon-filter {
    fill: black;
  }
  //margin-left: -60px;
}

.ag-theme-balham .ag-row-selected {
  background-color: #dcb7ff;
  background-color: var(--ag-selected-row-background-color, #e4ecf0);
}

.ag-theme-balham .ag-header-cell::after,
.ag-theme-balham .ag-header-group-cell::after {
  width: 0px;
}

.ag-paging-panel {
  justify-content: space-between;
  display: flex;
}
.ag-paging-panel .ag-paging-number {
  font-weight: 700;
}
.ag-paging-panel .ag-paging-row-summary-panel-number {
  font-weight: 700;
}

// .ag-theme-alpine .ag-header-cell{
//     padding-left: 10px;
//     padding-right: 10px;
// }
.widthOverride {
  width: 165px;
}

// .p-disabled, .p-disabled *{
//     background-color: lightgrey;
// }

// .p-multiselect:disabled .p-multiselect-trigger:disabled {
//     background: lightgrey;
// }

.background {
  position: relative;
  float: left;
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: 200px;
  //height: 100%;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  opacity: 1;
}
.toolBox {
  float: left;
  position: relative;
  top: 10px;
  left: 24px;
  width: 96%;
  height: 60px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}

.gridContent {
  position: relative;
  float: left;
  top: 1%;
  left: 1%;
  right: 1%;
  width: 98%;
  margin-bottom: 5%;
  //background: white 0% 0% no-repeat padding-box;
  opacity: 1;
  color: blue;
}
.ag-header-row-column {
  background: #e4ecf0 0% 0% no-repeat padding-box !important;
}
.gridContent1 {
  position: relative;
  float: left;
  top: 1%;
  left: 0%;
  right: 1%;
  width: 98%;
  margin-bottom: 1%;
  //background: white 0% 0% no-repeat padding-box;
  opacity: 1;
}
.gridStyle {
  margin: auto;
  top: 1%;
  left: 1%;
  right: 1%;
  width: 98%;
  margin-bottom: 3.5%;
}
.saveCancel {
  //position: relative;
  margin-left: 1%;
  //top : 85%;
  margin-right: 1%;
  width: 98%;
  min-height: 55px;
  //0% 0% no-repeat padding-box
  background: #e4ecf0;
  border: 1px solid #bcd1e0;
  opacity: 1;
  margin-top: -3%;
  margin-bottom: 3%;
}
// .gridcontent2 {
//   background-color: #d20000;
//   text-decoration: underline;
//   cursor: pointer;
//   color: blue;
// }
.heading {
  position: relative;
  top: 1%;
  // margin-left: 2%;
  text-align: left;
  font: normal normal normal 25px/30px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #161718;
  opacity: 1;
  margin: 0 !important;
}
.tbxchildLabel {
  float: left;
  text-align: bottom right;
  padding-top: 10px;
}
.tbxchildctrl {
  float: left;
  padding-top: 13px;
  padding-left: 10px;
  //width: 10%;
}
.devanall{
  width: 94px;
  font-size: 12px;
  border-radius: 35px;
  font-weight: 600;
  padding: 5px;
  cursor:pointer;
  color: #ffffff;
  background-color:#ff0000;
  border-color:red;




}
.devanall:disabled,
.devanall[disabled]{
  width: 94px;
  font-size: 12px;
  border-radius: 35px;
  font-weight: 600;
  padding: 5px;
  color: white;
  // border-radius: 0px;
  background-color:#a79d9d;
  border-color:rgb(192, 180, 180);


}
.searchresult {
  float: left;
  position: relative;
  top: 30px;
  // top: 10px;
  left: 24px;
  width: 96%;
  height: 10%;
  background: #e8eef2 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}
.searchLable {
  position: relative;
  font: Bold 11px/25px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #161718;
  opacity: 1;
  width: 100%;
  // padding-top: 10px;
  // margin-left:12px;
}

.search_btn {
  text-transform: uppercase;
  text-align: center;
  font: 500 normal normal 13px/18px Roboto, sans-serif;
  position: relative;
  left: 10%;
  width: 90px;
  height: 35px;
  color: white;
  border-radius: 35px;
  background: #e10a1d;
  border: 0;
  //background: #161718 0% 0% no-repeat padding-box;
  //border: 1px solid #161718;
  padding: 9px;
  opacity: 1;
}
.v1 {
  border-left: 3px solid #d20000;
  height: 5px;
  margin-right: 4px;
}
.search_btn:hover {
  background: #eb0a1e;
  text-decoration: underline;
}
.search_btn:disabled,
.search_btn[disabled] {
  text-transform: uppercase;
  text-align: center;
  font: 500 normal normal 13px/18px Roboto, sans-serif;
  position: relative;
  left: 10%;
  width: 90px;
  height: 35px;
  color: white;
  border-radius: 35px;
  // border-radius: 0px;
  background: #7b7c7c 0% 0% no-repeat padding-box;
  border: 0;
  //background: #161718 0% 0% no-repeat padding-box;
  //border: 1px solid #161718;
  padding: 9px;
  opacity: 1;
}
.uploadloadbtn {
  text-transform: uppercase;
  text-align: center;
  font: normal normal normal 13px/18px Roboto, sans-serif;
  position: relative;
  top: 1%;
  float: right;
  width: 110px;
  height: 35px;
  color: white;
  border-radius: 35px;
  background: #e10a1d;
  border: 0;
  opacity: 1;
}
.review_btn {
  position: relative;
  top: 9px;
  float: right;
  width: 125px;
  height: 40px;
  color: white;
  background: #e10a1d;
  //background: #e10a1d;
  border: none !important;
  border-radius: 35px !important;
  opacity: 1;
  right: 2%;
  font-weight: 500;
  padding: 9px;
  font-size: 13px;
}
.review_btn_match {
  position: relative;
  top: 9px;
  float: right;
  width: 125px;
  height: 40px;
  color: white;
  background: #22b785;
  //background: #e10a1d;
  border: none !important;
  border-radius: 35px !important;
  opacity: 1;
  // right: 2%;
  font-weight: 500;
  padding: 9px;
  font-size: 13px;
}
.review_btn_mismatch {
  position: relative;
  top: 9px;
  font: 500 normal normal 13px/18px Roboto, sans-serif;
  float: right;
  width: 100px;
  height: 33px;
  color: #ce2121;
  background: white 0% 0% no-repeat padding-box;
  border: 1px solid #ce2121 !important;
  border-radius: 35px !important;
  opacity: 1;
  right: 2%;
  margin-right: 8px !important;
}
.cancel_btn_seal {
  position: relative;
  top: 9px;
  font: 500 normal normal 13px/18px Roboto, sans-serif;
  float: right;
  width: 120px;
  height: 40px;
  color: black;
  background: white 0% 0% no-repeat padding-box;
  border: 1px solid #161718 !important;
  border-radius: 35px !important;
  opacity: 1;
  right: 10%;
  margin-right: 8px !important;
  
}
.seal_verify {
  position: relative;
  // top: 9px;
  font: 500 normal normal 13px/18px Roboto, sans-serif;
  float: right;
  width: 100px;
  height: 30px;
  color: rgb(36, 40, 248);
  background: white 0% 0% no-repeat padding-box;
  border: 1px solid #0b37f8 !important;
  border-radius: 35px !important;
  opacity: 1;
  // right: 2%;
  // margin-right: 8px !important;
  
}
.cancel_btn {
  position: relative;
  top: 9px;
  font: 500 normal normal 13px/18px Roboto, sans-serif;
  float: right;
  width: 120px;
  height: 40px;
  color: black;
  background: white 0% 0% no-repeat padding-box;
  border: 1px solid #161718 !important;
  border-radius: 35px !important;
  opacity: 1;
  right: 2%;
  margin-right: 8px !important;
}
.close {
  font-weight: 400;
  border: none;
  background: black;
  font-size: 20px;
  color: white;
}
.btn-circle.btn-xl {
  width: 26px;
  height: 26px;
  padding: 0px 7px;
  border-radius: 60px;
  font-size: 16px;
  text-align: center;
  margin: -25px 0px 18px 27px;
  position: fixed;
  z-index: 99999999;
  font-weight: bold;
}

.vertical {
  position: relative;
  border-left: 2px solid #ddd;
  left: 3%;
  width: 4px;
  height: 8px;
  font: Bold 16px/48px Roboto, sans-serif;
  letter-spacing: 0px;
  opacity: 1;
}
.searchinfoLabel {
  position: relative;
  text-align: right;
  font: normal normal bold 13px/16px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #707070;
  text-transform: uppercase;
  opacity: 1;
}
.searchinfoValue {
  white-space: pre;
  position: relative;
  text-align: left;
  font: normal normal bold 14px/16px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.ui-grid-cell-contents-auto {
  /* Old Solution */
  /*min-width: max-content !important;*/
  /* New Solution */
  // display: grid;
  //grid-auto-columns: max-content;
  /* IE Solution */
  display: -ms-grid;
  -ms-grid-columns: max-content;
  //min-width: 225px;
}
.grid-outer-layout {
  //top: 100px;
  //left: 20px;
  float: left;
  width: 100%;
  //height: 40%;
  //padding-top: 40px;
  //margin-left: 20px;
  //margin-top: 60px;
  // background-color: #2b1414;
}
.grid-outer-shadow {
  //background:#FFFFFF 0% 0% no-repeat padding-box;
  //box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}
.tool-outer {
  /* Layout Properties */
  //top: 384px;
  //left: 0%;
  width: 100%;
  float: left;
  //height: 20%;
  /* UI Properties */
  opacity: 1;
}
.page-drpdwn-label {
  position: relative;
  padding-top: 5px;
  margin-right: 2%;
  text-align: right;
  font: Bold 11px/16px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  flex: 8;
}
.drpDiv {
  position: relative;
  float: left;
  flex: 1;
}
.page-drpdwn {
  position: relative;
  width: 54px;
  height: 26px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1d1d1;
  opacity: 1;
  font: normal normal bold 13px/16px Roboto, sans-serif;
  margin-right: 3px;
}
.records {
  display: flex;
  //text-align: right;
}
.table-outer {
  position: relative;
  top: 5px;
  width: 100%;
  // background: #000000;
}
.div-emptydata {
  text-align: center;
}
.p-text {
  text-align: center;
  font: normal normal normal 20px/30px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #161718;
  opacity: 1;
}
.p-nodata-content {
  width: 40%;
  text-align: center;
  font: normal normal normal 13px/18px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #676a6e;
  opacity: 1;
  margin-left: 30%;
}
.img-tool {
  padding: 4px;
  border: 1px solid #d1d1d1;
  background: transparent linear-gradient(180deg, #ffffff 0%, #e4ecf0 100%) 0%
    0% no-repeat padding-box;
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.editsavesticky {
  position: fixed;
  top: 85%;
  margin-left: 1%;
  margin-right: 1%;
  width: 98%;
  min-height: 55px;
  background: #e4ecf0 0% 0% no-repeat padding-box;
  border: 1px solid #bcd1e0;
  opacity: 1;
}
.card {
  float: left;
  position: relative;
  margin: 1% 1%;
  width: 98% !important;
  min-height: 60px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  padding-top: 1%;
  padding-bottom: 1%;
}
.header {
  padding-left: 20px;
  padding-top: 10px;
  color: #3699e0;
  font: bold 11px/18px Roboto, sans-serif;
}
.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto {
  position: relative;
  //width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.margin-imagetag-download {
  //margin-left: 1rem;
  padding: 4px;
  border: 1px solid #d1d1d1;
  background: transparent linear-gradient(180deg, #ffffff 0%, #e4ecf0 100%) 0%
    0% no-repeat padding-box;
  //margin-left: 15px;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.margin-imagetag-download1 {
  margin-left: 3rem;
  padding: 4px;
  border: 1px solid #d1d1d1;
  background: transparent linear-gradient(180deg, #ffffff 0%, #e4ecf0 100%) 0%
    0% no-repeat padding-box;
  //margin-left: 15px;
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.drpchildctrl {
  float: left;
  //padding-top: 13px;
  padding-left: -20px !important;
  width: 100%;

  .label {
    text-align: left;
    // font: normal normal medium 16px/21px Roboto !important;
    letter-spacing: 0px;
    color: #161718;
    text-transform: uppercase;
    opacity: 1;
  }
  input {
    text-align: left;
    font: normal bold normal 12px/10px Roboto, sans-serif;
    letter-spacing: 0px;
    color: #161718;
    /* text-transform: capitalize; */
    /* opacity: 1; */
    /* height: 80%; */
    width: 100%;
    /* padding: 0px 1px 0px 1px; */
  }
}
.mat-select-value {
  color: #d20000 !important;
}
.mat-form-field-infix {
  width: 140px;
}
.p-dropdown .p-dropdown-trigger {
  color: #e10a1d;
  font-size: 12px !important;
  font-weight: 500;
}
.p-multiselect-trigger {
  color: #e10a1d !important;
  width: 1.357rem !important;
}
.p-component {
  font-size: 12px !important;
}
.edit_icon {
  margin-left: 3px;
}
.p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-filter-container
  .p-inputtext {
  height: 25px !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin: 0px !important;
}

.p-multiselect .p-multiselect-label {
  padding: 3px;
  font: normal 12px/16px Roboto, sans-serif;
}

.p-checkbox .p-checkbox-box {
  margin: 2px 0;
}
.resetlabel {
  padding: 0 16px 0 4px;
  cursor: pointer;
}
.resetIcon {
  cursor: pointer;
}
.resetlabel1 {
  padding: 100 500px 0 10px;
  cursor: pointer;
}

.resetlabel:hover {
  cursor: pointer;
  color: #2688da;
  text-decoration: underline;
}
.resetlabel1:hover {
  cursor: pointer;
  color: #2688da;
  text-decoration: underline;
}

.ag-theme-balham {
  margin-bottom: 0px !important;
  height: 59vh;
  font-size: 13px;
  font-weight: 500;
}

.mat-select-arrow {
  color: #e10a1d !important;
}
.ag-popup {
  height: 0 !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: red;
}
::-webkit-calendar-picker-indicator {
  filter: invert(10%) sepia(84%) saturate(6169%) hue-rotate(350deg)
    brightness(102%) contrast(99%); //color:green;
  //background-color: red;
  //color-scheme: red;
}
.loading {
  font-size: 20px;
  color: white;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0 !important;
  padding: 0.3rem 0.2rem !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0 !important;
  padding: 2px 15px !important;
}
.mat-card-header-text {
  width: 100% !important;
}

// AG Grid sell click - focus - remove border
::ng-deep.ag-cell-focus,
.ag-cell-no-focus {
  border: none !important;
}

.ui-grid-cell-focus {
  border: 0 !important;
}

.mat-select-disabled .mat-select-trigger .mat-select-arrow {
  visibility: hidden;
}

// To hide filter icon from ag grid header
// - so that it doesn't show two header icons when any filter(number, text) is active
.ag-header-icon.ag-filter-icon.ag-header-label-icon .ag-icon.ag-icon-filter {
  visibility: hidden;
  display: none;
  height: 0px;
  width: 0px;
  padding: 0px;
  margin: 0px;
}

.mb-35 {
  margin-bottom: 35px;
}
.ag-theme-balham input[class^="ag-"]:not([type]),
.ag-theme-balham input[class^="ag-"][type="text"],
.ag-theme-balham input[class^="ag-"][type="number"],
.ag-theme-balham input[class^="ag-"][type="tel"],
.ag-theme-balham input[class^="ag-"][type="date"],
.ag-theme-balham input[class^="ag-"][type="datetime-local"],
.ag-theme-balham textarea[class^="ag-"] {
  font: normal bold normal 12px/10px Roboto, sans-serif;
  line-height: inherit;
  border-width: 1px;
  border-style: solid;
  border-color: #95a5a6;
  border-color: var(--ag-input-border-color, #95a5a6);
  color: #161718;
}
